<template>
  <div class="expert_page">
    <div class="banner">
      <img src="@/assets/images/banzhuan/expert/banner.jpg" alt="" />
    </div>
    <div class="content">
      <img
        src="@/assets/images/banzhuan/expert/expert_icon1.png"
        alt=""
        class="expert_icon1"
      />
      <img src="@/assets/images/banzhuan/expert/expert111.png" alt="" />
      <!--<div class="video">
        <video src="https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/zhaoqing.mp4" controls="controls" poster="@/assets/images/banzhuan/expert/zhaoqing.jpg"></video>
      </div>-->
      <img src="@/assets/images/banzhuan/expert/expert22.jpg" alt="" />
      <div class="video">
        <video
          src="https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/wushi.mp4"
          controls="controls"
          poster="@/assets/images/banzhuan/expert/wushi.jpg"
        ></video>
      </div>
      <img
        src="@/assets/images/banzhuan/expert/expert_icon2.png"
        alt=""
        class="expert_icon1"
      />
      <img src="@/assets/images/banzhuan/expert/expert333.png" alt="" />
      <div class="video">
        <video src="https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/liuzhong.mp4" controls="controls" poster="@/assets/images/banzhuan/expert/liuzhong.jpg"></video>
      </div>
      <img
        src="@/assets/images/banzhuan/expert/expert_icon3.png"
        alt=""
        class="expert_icon2"
      />
      <img src="@/assets/images/banzhuan/expert/expert411.png" alt="" />
      <div class="video">
        <video src="https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/kiki.mp4" controls="controls" poster="@/assets/images/banzhuan/expert/kiki.jpg"></video>
      </div>
      <img src="@/assets/images/banzhuan/expert/expert51.jpg" alt="" />
      <div class="video">
        <video src="https://xcxyc.oss-cn-hangzhou.aliyuncs.com/yusu/media/xiaoxuan.mp4" controls="controls" poster="@/assets/images/banzhuan/expert/xiaoxuan.jpg" height="500px"></video>
      </div>
       <img src="@/assets/images/banzhuan/expert/expert71.jpg" alt="" />
        <img src="@/assets/images/banzhuan/expert/expert61.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expert',
  components: {},
}
</script>
<style scoped lang='scss'>
.expert_page {
  .content {
    width: 1460px;
    margin: 0 auto;
    text-align: center;
    .expert_icon1 {
      margin: 60px auto 40px;
    }
    .expert_icon2 {
      margin: 160px auto 80px;
    }
    video {
      width: 100%;
      margin: 30px auto;
    }
  }
}
</style>
